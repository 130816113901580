import { usePublisherStore } from "~/store/PublisherModule";

type RequestMethods = "GET" | "POST" | "PUT" | "DELETE";
type UserType = "publisher" | "advertiser";

interface IRequestOptions {
  method?: RequestMethods;
  body?: any;
  params?: Record<string, any>;
  query?: string;
}

const getAuthToken = (type: UserType): string | null => {
  if (type === "publisher") {
    const cookieToken = useCookie("authorizedP").value;
    if (cookieToken && cookieToken !== "undefined") return cookieToken;
    
    const localToken = localStorage.getItem("usePublisherStore__authorized");
    if (localToken) return localToken;
    
    return usePublisherStore().authorized;
  }
  
  return useCookie("accessToken").value ?? null;
};

const getHeaders = (token: string | null) => {
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };
  
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  
  return headers;
};

const handleError = (error: any, endpoint: string) => {
  const serverError = error.data || error.response?.data || error;

  if (serverError.code === 1513) {
    return { error: serverError };
  }

  if (/^\/api\/v1\/chat\/publication\/\d+$/.test(endpoint)) {
    return null;
  }

  console.error("Chat API Error:", {
    status: error.statusCode,
    message: serverError.message,
    code: serverError.code,
    debug: serverError.debug,
    timestamp: serverError.timestamp,
  });

  throw new Error(serverError.message || `Query error for ${endpoint}`);
};

export const chatApi = async (
  endpoint: string, 
  options: IRequestOptions = {}, 
  type: UserType = "publisher"
) => {
  try {
    const config = useRuntimeConfig();
    const token = getAuthToken(type);
    const headers = getHeaders(token);
    const query = options.query || "";

    return await $fetch(`${config.public.CHAT_API_URL}${endpoint}${query}`, {
      method: options.method || "GET",
      body: options.body,
      headers,
      params: options.params,
    });
  } catch (error) {
    return handleError(error, endpoint);
  }
};
